.inventory-page {
  padding: 30px;
  background-color: #f4f7f9;
  border-radius: 12px;
  margin: 20px auto;
  width: 90%;
  font-family: Arial, sans-serif;
}

/* Header Container Styles */
.header-container {
  text-align: center;
  background-color: #f2f4f8;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

/* Header Title */
.header-container h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

/* Filter Container Styles */
.filter-container {
  display: flex;
  justify-content: right;
  align-items: right;
  margin-bottom: 20px;
}

.filter-dropdown {
  width: 150px;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
  outline: none;
  cursor: pointer;
}

.filter-dropdown:hover {
  border-color: #007bff;
}

/* Inventory Table Styles */
.inventory-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.inventory-table th,
.inventory-table td {
  padding: 12px 16px;
  border: 1px solid #ddd;
  text-align: left;
  font-size: 16px;
}

.inventory-table th {
  background-color: #f9f9f9;
  font-weight: 600;
  color: #666;
}

.inventory-table td {
  background-color: #fff;
  color: #333;
}

.inventory-table tr:nth-child(even) {
  background-color: #f7f7f7;
}

.inventory-table tr:hover {
  background-color: #eaf1f8;
}

/* Pagination Styles */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 15px;
}

.pagination-controls button {
  background-color: #007bff;
  color: white;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.pagination-controls button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination-controls span {
  font-size: 18px;
}