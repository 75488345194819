.pants-product-table {
  margin: 20px auto;
  font-family: Arial, sans-serif;
  width: 80%;
  border-collapse: collapse;
  font-size: 18px;
  /* Increase the base font size for the entire table */
}

.header-container {
  display: flex;
  align-items: center;
  /* Vertically aligns items */
  gap: 10px;
  /* Adds a small gap between the heading and the button */
}

.header-container h2 {
  margin: 0;
  /* Removes unnecessary margins around the heading */
  font-size: 28px;
  /* Increase heading font size */
  font-weight: bold;
}

.add-new-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 20px;
  /* Adds some space between heading and button */
  font-size: 18px;
  /* Increase font size of the button */
}

.add-new-button:hover {
  background-color: #0056b3;
}

.pants-product-table table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  border: 1px solid #ddd;
}

.pants-product-table th,
.pants-product-table td {
  padding: 16px;
  /* Increase padding for a larger appearance */
  text-align: left;
  border: 1px solid #ddd;
  font-size: 18px;
  /* Increase font size of table content */
}

.pants-product-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  text-align: center;
}

.pants-product-table td {
  background-color: #fff;
  text-align: center;
}

.pants-product-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.pants-product-table button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 15px;
  /* Increase padding for larger buttons */
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  /* Increase button text size */
}

.pants-product-table button:hover {
  background-color: #45a049;
}

.pants-product-table td:last-child {
  text-align: center;
}

.pants-product-table th:first-child,
.pants-product-table td:first-child {
  text-align: left;
}

.pants-product-table th:last-child,
.pants-product-table td:last-child {
  text-align: center;
}

.pants-product-table .delete-button {
  background-color: #f44336;
}

.pants-product-table .delete-button:hover {
  background-color: #d32f2f;
}

.pants-product-table .edit-button {
  margin-right: 5px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease;
}

.modal-content {
  background-color: #fefefe;
  padding: 30px;
  border-radius: 12px;
  width: 500px;
  max-width: 90%;
  text-align: center;
  font-size: 18px;
  /* Increase font size inside modal */
}

.plus-icon {
  font-size: 24px;
  color: white;
  background-color: green;
  border: none;
  cursor: pointer;
  width: 40px;
  /* Define the width of the button */
  height: 40px;
  /* Make sure the height matches the width to create a perfect circle */
  border-radius: 100%;
  /* This creates the circular shape */
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-container {
  display: flex;
  justify-content: flex-end;
  /* Align the filter and button to the right */
  align-items: center;
  gap: 15px;
  /* Adds space between the button and filter */
  width: 100%;
  /* Ensure the container takes full width */
}

.filter-container {
  display: flex;
  align-items: center;
  margin: 0;
}

.filter-container label {
  margin-right: 10px;
  font-size: 16px;
}

.filter-dropdown {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
  outline: none;
  cursor: pointer;
}

.filter-dropdown:hover {
  border-color: #007bff;
  /* Change border color on hover */
}