/* Centering the homepage content */
.homepage-container {
    text-align: center;
    margin: 50px auto;
    max-width: 600px;
}

/* Styling the button container */
.button-container {
    display: flex;
    flex-direction: column;
    /* Set buttons to be in a column */
    align-items: center;
    /* Center align the buttons */
    gap: 20px;
    /* Space between each button */
    margin-top: 50px;
}

/* Styling the buttons */
.page-button {
    background-color: #28a745;
    /* Set background to green */
    color: white;
    font-size: 18px;
    padding: 15px 40px;
    /* Adjust padding */
    width: 250px;
    /* Set a fixed width for all buttons */
    text-align: center;
    /* Center the text within the button */
    border: none;
    border-radius: 8px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.page-button:hover {
    background-color: #218838;
    /* Darker green on hover */
    transform: translateY(-3px);
    /* Slight hover effect */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .button-container {
        width: 100%;
        padding: 0 20px;
    }

    .page-button {
        width: 100%;
        /* Full width for buttons on smaller screens */
    }
}