/* General Styles */
.order-table {
  margin: 20px auto;
  font-family: Arial, sans-serif;
  width: 80%;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f2f2;
  padding: 15px 25px;
  border-radius: 12px 12px 0 0;
  border: 1px solid #ddd;
  border-bottom: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header-container h2 {
  margin: 0;
  font-size: 26px;
  flex-grow: 1;
}

.button-group {
  display: flex;
  gap: 15px;
}

.add-order-button,
.view-history-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.add-order-button:hover,
.view-history-button:hover {
  background-color: #0056b3;
}

.view-history-button {
  text-decoration: none;
  display: inline-block;
  line-height: 32px;
}

.view-history-button:hover {
  background-color: #d9534f;
}

/* Table Styles */
.order-table table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
  border: 1px solid #ddd;
  font-size: 18px;
}

.order-table th,
.order-table td {
  padding: 15px;
  text-align: left;
  border: 1px solid #ddd;
}

.order-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
}

.order-table td {
  background-color: #fff;
  text-align: center;
  font-size: 18px;
}

.order-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Row Status Styles */
.pending-status {
  background-color: #f8d7da;
  /* Light red for Pending */
  color: black;
}

.preparing-status {
  background-color: #fff3cd;
  /* Light yellow for Preparing */
  color: blue;
}

.order-table button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}

.order-table button:hover {
  background-color: #45a049;
}

/* Custom Dropdown Styles */
.status-dropdown {
  background-color: #f9f9f9;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 12px;
  width: 150px;
  font-size: 16px;
  transition: all 0.3s ease;
  outline: none;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.status-dropdown:hover,
.status-dropdown:focus {
  border-color: #007bff;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
}

.status-dropdown option {
  padding: 10px;
}

.status-dropdown:active {
  border-color: #0056b3;
}

/* Status-specific Option Styles */
.status-dropdown option[value='Pending'] {
  background-color: #f8d7da;
  /* Light red for Pending */
  color: #721c24;
}

.status-dropdown option[value='Preparing'] {
  background-color: #fff3cd;
  /* Light yellow for Preparing */
  color: #856404;
}

.status-dropdown option[value='Done'] {
  background-color: #d4edda;
  /* Light green for Done */
  color: #155724;
}

/* Modal Styles */
/* Modal overlay */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

/* Modal content */
.modal-content {
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
  width: 550px;
  max-width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.modal-content:hover {
  transform: translateY(-5px);
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.2);
}

/* Title styling */
.modal-title {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* Input groups */
.input-group {
  margin-bottom: 20px;
}

.input-label {
  display: block;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}

.input-field {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
  font-size: 16px;
}

.input-field:focus {
  outline: none;
  border-color: #56ccf2;
  box-shadow: 0 0 8px rgba(86, 204, 242, 0.5);
}

/* Adjusted styles for input fields */
.input-field.color-input,
.input-field.size-input,
.quantity-input {
  width: 100%;
}

.quantity-input {
  margin-bottom: 2px;
}

/* Product card styling */
.product-card {
  background: #f4f7f9;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Product field group styling */
.product-row {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.product-field-group {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  min-width: 0;
}

.locked-field {
  padding: 10px;
  background: #eaeaea;
  border-radius: 8px;
  color: #666;
  text-align: center;
}

/* Button styling */
button {
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 20px;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.add-button {
  background-color: #56ccf2 !important;
  color: white;
  margin-top: 48px !important;
}

.add-button:hover {
  background-color: #2f80ed;
  transform: translateY(-3px);
}

.remove-product-button {
  background-color: #ff6b6b;
  color: white;
  margin-top: 22px;
}

.remove-product-button:hover {
  background-color: #ff3b3b;
  transform: translateY(-3px);
}

/* Modal footer buttons */
.modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.save-button {
  background-color: #56ccf2;
  color: white;
  flex: 1;
  margin-right: 10px;
}

.save-button:hover {
  background-color: #2f80ed;
}

.cancel-button {
  background-color: #d9534f;
  color: white;
  flex: 1;
}

.cancel-button:hover {
  background-color: #c9302c;
}

/* Add product button styling */
.add-product-button {
  background-color: #5cb85c;
  color: white;
  width: 100%;
  text-align: center;
  padding: 15px;
  border-radius: 8px;
  margin-top: 20px;
}

.add-product-button:hover {
  background-color: #4cae4c;
  transform: translateY(-3px);
}

/* Animation for the modal */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Adjustments for mobile view */
@media (max-width: 768px) {
  .modal-content {
    width: 90%;
    padding: 15px;
    max-height: 90vh;
  }

  .product-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .product-field-group {
    width: 100%;
  }

  .input-field.color-input,
  .input-field.size-input {
    width: 100%;
  }

  .quantity-input {
    width: 100%;
    padding: 8px;
  }

  .add-button,
  .remove-product-button,
  .save-button,
  .cancel-button,
  .add-product-button {
    width: 100%;
    padding: 15px;
    margin-top: 10px;
  }

  /* Adjust button spacing */
  .modal-footer {
    flex-direction: column;
  }
}

/* Note Modal Styles */
.note-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.note-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.note-modal-content h3 {
  margin-bottom: 15px;
  font-size: 24px;
}

.note-modal-content p {
  margin-bottom: 20px;
}

.note-modal-content button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.note-modal-content button:hover {
  background-color: #0056b3;
}