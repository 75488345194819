.navbar {
  background-color: #4CAF50;
  /* Green color similar to your screenshot */
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  font-family: 'Roboto', sans-serif;
  /* Use a more mature font like Roboto */
}

.navbar-logo h1 {
  margin: 0;
  font-size: 28px;
  /* Adjust font size to make it look balanced */
  font-weight: 700;
  /* Increase font weight for a stronger appearance */
}

.navbar-links {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  margin-left: 20px;
}

.navbar-links li a {
  color: white;
  text-decoration: none;
  font-weight: 600;
  /* Change font weight for a more professional look */
  font-size: 16px;
  /* Set font size to maintain readability */
}

.navbar-links li a:hover {
  text-decoration: underline;
}